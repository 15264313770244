import React from "react"
import { t, Trans } from "@lingui/macro"
import { BaseLayout } from "../layouts/base-layout"
import { Seo } from "../components/seo"

const ImprintPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 100)

    return () => clearTimeout(timeout)
  })

  return (
    <BaseLayout>
      <Seo
        title={t`Imprint`}
        description={t`Testframe is a photo testing tool that helps people choose their best photos.`}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      {isLoading ? null : (
        <>
          <h1 className="text-4xl font-extrabold">
            <Trans>Imprint</Trans>
          </h1>

          <div className="mt-3">
            <Trans>
              The app was developed and published by Dmitrii Solovev
            </Trans>
            :
            <br />
            <br />
            E-Mail: support@testframe.app
            <br />
            <Trans>Postal address</Trans>: Dmitrii Solovev, Trusetaler Str. 84,
            12687 Berlin, Germany
            <br />
            <br />
            <Trans>Last update</Trans>: 01.09.2022
          </div>
        </>
      )}
    </BaseLayout>
  )
}

export default ImprintPage
